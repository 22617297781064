import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss'],
  standalone: false
})
export class InfoItemComponent {
  @Input() text?: string
  @Input() icon: string
  @Input() iconClass = ''
  @Input() extraClass = ''
  @Input() shouldBreak = true
}
