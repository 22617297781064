import { AfterViewInit, Component, HostListener, inject, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngxs/store'
import { Router } from '@angular/router'
import { IUser, Logout, ReferralType, UserAccountState } from '@mg-platform/users/users-data-access'
import { Observable } from 'rxjs'
import { MobileMaxWidth } from '@mg-platform/core/core-util'
import { IHeaderRouteInfo } from './main-header.interface'
import { StateResetAll } from 'ngxs-reset-plugin'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatDialog } from '@angular/material/dialog'
import { SidebarElement, SidebarPosition } from 'sidebarjs'

@UntilDestroy()
@Component({
  selector: 'mg-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  standalone: false
})
export class MainHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() forAdmin = false
  @Input() mainMenuRoutes: IHeaderRouteInfo[]
  @Input() profileMenuRoutes: IHeaderRouteInfo[]
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)
  currentUser: IUser

  referralType = ReferralType

  mainSidebar: SidebarElement
  profileSidebar: SidebarElement
  showThemeOptions = false

  constructor(
    private store: Store,
    private router: Router,
    private dialogService: MatDialog
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (!this.forAdmin && event.target.innerWidth > MobileMaxWidth) {
      this.dialogService.closeAll()
    }
  }

  ngOnInit(): void {
    this.currentUser$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.currentUser = res
    })
  }

  ngAfterViewInit(): void {
    this.mainSidebar = new SidebarElement({
      component: document.querySelector('[sidebarjs="mainSidebar"]') as HTMLElement,
      documentSwipeRange: 0,
      onOpen: () => {
        document.body.style.overflow = 'hidden'
      },
      onClose: () => {
        document.body.style.overflow = 'auto'
      }
    })

    this.profileSidebar = new SidebarElement({
      component: document.querySelector('[sidebarjs="profileSidebar"]') as HTMLElement,
      documentSwipeRange: 0,
      position: SidebarPosition.Right,
      onOpen: () => {
        document.body.style.overflow = 'hidden'
      },
      onClose: () => {
        document.body.style.overflow = 'auto'
        this.showThemeOptions = false
      }
    })
  }

  ngOnDestroy(): void {
    this.mainSidebar?.destroy()
    this.profileSidebar?.destroy()
    document.body.style.overflow = 'auto'
  }

  logout() {
    this.store.dispatch(new Logout()).subscribe(() => {
      this.router.navigateByUrl('/login')
      this.store.dispatch(new StateResetAll())
    })
  }
}
