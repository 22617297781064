<div class="bg-contrast h-full w-full relative p-lg overflow-auto">
  <!-- Close Btn -->
  <div class="flex absolute text-txt-secondary cursor-pointer top-lg right-xl">
    <svg-icon key="close" class="!text-base" (click)="dialogRef.close()"></svg-icon>
  </div>

  <mg-item-selector
    pillSize="m"
    [items]="data.items"
    [selectedItem]="data.selectedItem"
    [isGroup]="data.isGroup"
    (selectedItemChanged)="dialogRef.close($event)"
  ></mg-item-selector>
</div>
