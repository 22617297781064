import { FilterStateKeys } from './filters.model'

export interface IPageQueryParamsMap {
  [key: string]: FilterStateKeys[]
}

export const MainPageQueryParamsMap: IPageQueryParamsMap = {
  '/market-pricing': ['shopId', 'groupId', 'compareWith'],

  '/weekly-reports': ['shopId', 'groupId', 'weekNumber'],

  '/comp-sales': ['shopId', 'groupId'],

  '/comp-sales/all-shops': ['shopId', 'groupId'],

  '/users/all-users': ['usersTableSortInfo'],

  '/shops/groups': ['myGroupsTableSortInfo'],

  '/shops/all': ['myShopsTableSortInfo'],

  '/shops/global-targets': ['shopId', 'groupId'],

  '/performance-calendar': ['shopId', 'groupId', 'marketType', 'calendarReportType', 'calendarViewDate'],

  '/goals': ['shopId', 'groupId'],

  '/benchmark': [
    'shopId',
    'groupId',
    'benchmarkSecondCompareWith',
    'benchmarkThirdCompareWith',
    'benchmarkCustomDateInfo',
    'benchmarkExpandStatus'
  ],

  '/weekly-trends': ['shopId', 'groupId', 'compareWith'],

  '/tire-trends': ['shopId', 'groupId', 'compareWith'],

  '/comp-trends': ['compTrendAvgSalesPerBay', 'compTrendAvgCarCount', 'compTrendAvgTicket'],

  '/reports/sales': ['shopId', 'groupId', 'salesTrackerDateInfo'],

  '/reports/gross-profit': ['shopId', 'groupId', 'grossprofitDateInfo'],

  '/leaderboard': ['rankingsDateInfo', 'rankingsSelectedGroupId'],

  '/categories/sales': ['shopId', 'groupId', 'salesByCategoryDateInfo', 'salesByCategorySortInfo']
}

export const AdminPageQueryParamsMap: IPageQueryParamsMap = {
  '/dashboard': ['adminExcludeBatch'],
  '/shops/all': ['adminShopsFilters'],
  '/users/all': ['adminUsersFilters'],
  '/organizations/all': ['adminOrganizationsFilters'],
  '/notifications/all': ['adminNotificationsFilters'],
  '/market-pricing/all': ['adminMarketPricingFilters']
}

export const PersistFilters: FilterStateKeys[] = ['adminExcludeBatch', 'benchmarkExpandStatus']
