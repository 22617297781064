import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-item-selector-dialog',
  templateUrl: './item-selector-dialog.component.html',
  styleUrls: ['./item-selector-dialog.component.scss'],
  standalone: false
})
export class ItemSelectorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ItemSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { items: ISelectItem[]; selectedItem: any; isGroup: boolean }
  ) {}
}
