<div class="relative">
  <mg-tippy-tooltip *ngIf="groupId && groupShopList.length > 0" [clickable]="true">
    <div
      class="!grid grid-flow-col gap-x-xl gap-y-2xs"
      [ngStyle]="{
        'grid-template-rows':
          'repeat(' +
          Math.ceil(groupListLength / (groupListLength < 11 ? 1 : groupListLength < 21 ? 2 : 3)) +
          ', minmax(0, 1fr))'
      }"
    >
      <div *ngFor="let item of groupShopList" class="flex items-center">
        <div class="mr-sm w-sm h-sm rounded-full bg-graph-5"></div>
        <div class="text-sm leading-sm text-black">{{ item.displayName }}</div>
      </div>
    </div>
  </mg-tippy-tooltip>

  <div
    class="text-center border rounded-full {{ extraClass }}"
    [ngClass]="{
      '!rounded-sm': groupId,
      'm-size': size === 'm',
      's-size': size === 's',
      'bg-ui-selected border-ui-selected text-white': selected,
      'border-gray-100 text-txt': !selected,
      'hover:bg-ui-hover': !selected && hasHoverEffect,
      'cursor-pointer': hasHoverEffect
    }"
  >
    {{ label }}
  </div>
</div>
