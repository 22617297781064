import { Component, Input, OnInit } from '@angular/core'
import { IShop, ShopGroupsState } from '@mg-platform/shops/shops-data-access'
import { Store } from '@ngxs/store'

export type PillSize = 'm' | 's'

@Component({
  selector: 'mg-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  standalone: false
})
export class PillComponent implements OnInit {
  @Input() label = ''
  @Input() groupId: string
  @Input() extraClass = ''
  @Input() selected = false
  @Input() hasHoverEffect = true
  @Input() size: PillSize

  Math = Math
  groupShopList: IShop[] = []
  groupListLength = 0

  constructor(private store: Store) {}

  ngOnInit(): void {
    const groups = this.store.selectSnapshot(ShopGroupsState.groups)
    if (this.groupId) {
      this.groupShopList = groups.find((el) => el.id === this.groupId)?.shops ?? []
      this.groupListLength = this.groupShopList.length
    }
  }
}
