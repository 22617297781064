import { Location } from '@angular/common'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
  IBenchmarkFilters,
  ICalendarBenchmarkFilters,
  ITrendFilters
} from '@mg-platform/reports/reports-data-access'
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store'
import { isEmpty, isObject, pick, pickBy } from 'lodash'
import {
  ResetCompareWith,
  ResetFilters,
  SetFilters,
  SetFiltersFromUrlQueryParams,
  SetUrlQueryParamsFromState
} from './filters.actions'
import { FiltersStateModel, FilterStateKeys } from './filters.model'
import { LOCALSTORAGE_KEYS, environment } from '@mg-platform/core/core-util'
import jwt_decode from 'jwt-decode'
import sign from 'jwt-encode'
import { MainPageQueryParamsMap, AdminPageQueryParamsMap, PersistFilters } from './filters.constant'
import { CompareWithInitialValue } from '../../interfaces/compare-with.interface'
import { PeriodType, PeriodTypeButtonsInfo } from '../../enums/period-type.enum'

@State<FiltersStateModel>({
  name: 'filters'
})
@Injectable()
export class FiltersState implements NgxsOnInit {
  constructor(
    private location: Location,
    private router: Router
  ) {}

  @Selector()
  static allCountries(state: FiltersStateModel) {
    return state.allCountries ?? []
  }

  @Selector()
  static marketType(state: FiltersStateModel) {
    return state.marketType
  }

  @Selector()
  static shopId(state: FiltersStateModel) {
    return state.shopId
  }

  @Selector()
  static groupId(state: FiltersStateModel) {
    return state.groupId
  }

  @Selector()
  static weekNumber(state: FiltersStateModel) {
    return state.weekNumber
  }

  @Selector()
  static benchmarkFilters(state: FiltersStateModel): IBenchmarkFilters {
    return {
      benchmarkType: state.benchmarkType,
      startDate: state.benchmarkStartDate,
      endDate: state.benchmarkEndDate
    }
  }

  @Selector()
  static calendarBenchmarkFilter(state: FiltersStateModel): ICalendarBenchmarkFilters {
    return {
      reportType: state.calendarReportType,
      viewDate: state.calendarViewDate
    }
  }

  @Selector()
  static compTrendFilters(state: FiltersStateModel): ITrendFilters {
    return {
      avgSalesPerBay: this.evaluateStringBoolean(state.compTrendAvgSalesPerBay),
      avgCarCount: this.evaluateStringBoolean(state.compTrendAvgCarCount),
      avgTicket: this.evaluateStringBoolean(state.compTrendAvgTicket)
    }
  }

  @Selector()
  static adminShopsFilters(state: FiltersStateModel) {
    return (
      state.adminShopsFilters ?? {
        page: 1
      }
    )
  }

  @Selector()
  static adminUsersFilters(state: FiltersStateModel) {
    return (
      state.adminUsersFilters ?? {
        page: 1
      }
    )
  }

  @Selector()
  static adminOrganizationsFilters(state: FiltersStateModel) {
    return (
      state.adminOrganizationsFilters ?? {
        page: 1
      }
    )
  }

  @Selector()
  static adminNotificationsFilters(state: FiltersStateModel) {
    return (
      state.adminNotificationsFilters ?? {
        page: 1
      }
    )
  }

  @Selector()
  static adminMarketPricingFilters(state: FiltersStateModel) {
    return (
      state.adminMarketPricingFilters ?? {
        page: 1
      }
    )
  }

  @Selector()
  static compareWith(state: FiltersStateModel) {
    const allCountries = state.allCountries ?? []
    const nationals = allCountries && allCountries.length > 0 ? [allCountries[0].value] : []
    return (
      state.compareWith ?? {
        shops: [],
        states: [],
        regions: [],
        groups: [],
        nationals
      }
    )
  }

  @Selector()
  static benchmarkSecondCompareWith(state: FiltersStateModel) {
    return state.benchmarkSecondCompareWith ?? { ...CompareWithInitialValue }
  }

  @Selector()
  static benchmarkThirdCompareWith(state: FiltersStateModel) {
    return state.benchmarkThirdCompareWith ?? { ...CompareWithInitialValue }
  }

  @Selector()
  static benchmarkCustomDateInfo(state: FiltersStateModel) {
    return state.benchmarkCustomDateInfo ?? { periodType: PeriodType.Custom }
  }

  @Selector()
  static benchmarkExpandStatus(state: FiltersStateModel) {
    return state.benchmarkExpandStatus ?? {}
  }

  @Selector()
  static salesTrackerDateInfo(state: FiltersStateModel) {
    return (
      state.salesTrackerDateInfo ?? {
        periodType: PeriodType.PreviousMonth,
        rangeDate: PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.PreviousMonth)?.extraInfo
      }
    )
  }

  @Selector()
  static grossprofitDateInfo(state: FiltersStateModel) {
    return (
      state.grossprofitDateInfo ?? {
        periodType: PeriodType.PreviousMonth,
        rangeDate: PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.PreviousMonth)?.extraInfo
      }
    )
  }

  @Selector()
  static rankingsDateInfo(state: FiltersStateModel) {
    return (
      state.rankingsDateInfo ?? {
        periodType: PeriodType.PreviousMonth,
        rangeDate: PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.PreviousMonth)?.extraInfo
      }
    )
  }

  @Selector()
  static rankingsSelectedGroupId(state: FiltersStateModel) {
    return state.rankingsSelectedGroupId
  }

  @Selector()
  static salesByCategoryDateInfo(state: FiltersStateModel) {
    return (
      state.salesByCategoryDateInfo ?? {
        periodType: PeriodType.SixMonthToDate,
        rangeDate: {
          from: PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.SixMonthToDate)?.extraInfo
            ?.from,
          to: PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.Yesterday)?.extraInfo?.to
        }
      }
    )
  }

  @Selector()
  static salesByCategorySortInfo(state: FiltersStateModel) {
    return state.salesByCategorySortInfo
  }

  @Selector()
  static adminExcludeBatch(state: FiltersStateModel) {
    return state.adminExcludeBatch
  }

  @Selector()
  static usersTableSortInfo(state: FiltersStateModel) {
    return state.usersTableSortInfo
  }

  @Selector()
  static myGroupsTableSortInfo(state: FiltersStateModel) {
    return state.myGroupsTableSortInfo
  }

  @Selector()
  static myShopsTableSortInfo(state: FiltersStateModel) {
    return state.myShopsTableSortInfo
  }

  @Selector()
  static userDetialsShops(state: FiltersStateModel) {
    return state.userDetialsShops
  }

  @Selector()
  static userDetialsInfo(state: FiltersStateModel) {
    return state.userDetialsInfo
  }

  ngxsOnInit(ctx: StateContext<FiltersStateModel>) {
    const persistObj = localStorage.getItem(LOCALSTORAGE_KEYS.FILTERS)
    if (!persistObj) {
      return
    }
    const filters: Partial<FiltersStateModel> = JSON.parse(persistObj)

    if (isEmpty(filters) || !isObject(filters)) {
      return
    }

    for (const key in filters) {
      ctx.patchState({ [key]: filters[key as FilterStateKeys] })
    }
  }

  @Action(ResetFilters)
  resetFilters(ctx: StateContext<FiltersStateModel>, { filterKeys }: ResetFilters) {
    if (!filterKeys) {
      return
    }
    for (let index = 0; index < filterKeys.length; index++) {
      const key = filterKeys[index]
      ctx.patchState({ [key]: undefined })
    }
    this.saveFiltersToLocalStorage(ctx.getState())
  }

  @Action(SetFilters)
  setFilters(ctx: StateContext<FiltersStateModel>, { filters }: SetFilters) {
    if (isEmpty(filters)) {
      return
    }
    for (const key in filters) {
      ctx.patchState({ [key]: filters[key as FilterStateKeys] })
    }
    this.saveFiltersToLocalStorage(ctx.getState())
    this.updateQueryParams(ctx.getState())
  }

  @Action(SetFiltersFromUrlQueryParams)
  setFiltersFromUrlQueryParams(
    ctx: StateContext<FiltersStateModel>,
    { filtersToken }: SetFiltersFromUrlQueryParams
  ) {
    try {
      if (!filtersToken || filtersToken === '') {
        return
      }
      const filters = jwt_decode<FiltersStateModel>(filtersToken)
      if (!filters) {
        return
      }
      const validFilters = this.pickValidFilters(filters)
      ctx.patchState({ ...validFilters })
      this.saveFiltersToLocalStorage(ctx.getState())
      this.updateQueryParams(ctx.getState())
    } catch (error) {
      return
    }
  }

  @Action(SetUrlQueryParamsFromState)
  setUrlQueryParamsFromState(ctx: StateContext<FiltersStateModel>) {
    this.updateQueryParams(ctx.getState())
  }

  @Action(ResetCompareWith)
  resetCompareWith(ctx: StateContext<FiltersStateModel>) {
    const allCountries = ctx.getState()?.allCountries ?? []
    const nationals = allCountries && allCountries.length > 0 ? [allCountries[0].value] : []
    ctx.patchState({
      compareWith: { ...CompareWithInitialValue, nationals },
      benchmarkSecondCompareWith: { ...CompareWithInitialValue },
      benchmarkThirdCompareWith: { ...CompareWithInitialValue }
    })
  }

  private updateQueryParams(state: FiltersStateModel) {
    const currentUrl = new URL(document.URL)
    let currentFilters = {}
    const filtersToken = Object.fromEntries(new URLSearchParams(currentUrl.search) as any)['filters']
    if (filtersToken && filtersToken !== '') {
      try {
        currentFilters = jwt_decode<FiltersStateModel>(filtersToken)
      } catch (error) {
        currentFilters = {}
      }
    }
    const currentPath = this.router.url.split('?')[0]
    const validFilters = this.pickValidFilters({ ...currentFilters, ...state })

    if (!validFilters) {
      this.location.replaceState(currentPath)
      return
    }

    this.location.replaceState(`${currentPath}?filters=${sign(validFilters, environment.jwtSecret)}`)
  }

  private pickValidFilters(filters: Partial<FiltersStateModel>) {
    const currentPath = this.router.url.split('?')[0]
    let pageQueryParams: (keyof FiltersStateModel)[]

    if (window.location.href.includes(environment.adminUrl)) {
      pageQueryParams = AdminPageQueryParamsMap[currentPath]
    } else {
      pageQueryParams = MainPageQueryParamsMap[currentPath]
    }

    if (!pageQueryParams) {
      return
    }
    return pickBy(filters, (value, key) => pageQueryParams.find((el) => el === key))
  }

  private saveFiltersToLocalStorage(state: FiltersStateModel) {
    const persistObj = pick(state, PersistFilters)
    if (persistObj && !isEmpty(persistObj)) {
      localStorage.setItem(LOCALSTORAGE_KEYS.FILTERS, JSON.stringify(persistObj))
    } else {
      localStorage.removeItem(LOCALSTORAGE_KEYS.FILTERS)
    }
  }

  private static evaluateStringBoolean(value?: string | boolean) {
    return value !== undefined ? value === 'true' || value === true : undefined
  }
}
