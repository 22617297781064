<div class="bg-contrast h-[100dvh] w-full relative p-lg overflow-auto">
  <div class="mt-2xl group">
    @if (!showThemeOptions) {
      <ng-container *ngFor="let item of routes">
        <div
          *ngIf="!item.hidden"
          class="menu-item"
          [routerLink]="item.path"
          routerLinkActive="!bg-ui-selected !text-white"
          [routerLinkActiveOptions]="{ exact: !!item.exact }"
          (click)="itemClicked.emit()"
        >
          <div class="menu-icon">
            <svg-icon [key]="item.icon" class="!text-lg"></svg-icon>
          </div>
          <div class="menu-item-label">
            <div class="pl-sm">{{ item.label }}</div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="profileMenu">
        <div
          class="menu-item cursor-pointer"
          (click)="showThemeOptions = true; themeOptionsStatusChanged.emit(true)"
        >
          <div class="menu-icon">
            <svg-icon key="palette" class="!text-lg"></svg-icon>
          </div>
          <div class="menu-item-label">
            <div class="pl-sm">Theme Options</div>
          </div>
        </div>
        <div class="menu-item cursor-pointer" (click)="logout()">
          <div class="menu-icon">
            <svg-icon key="logout" class="!text-lg"></svg-icon>
          </div>
          <div class="menu-item-label">
            <div class="pl-sm">Log Out</div>
          </div>
        </div>
      </ng-container>
    } @else {
      <mg-update-theme-card extraClasses="!p-0 !overflow-visible"></mg-update-theme-card>
    }
  </div>
</div>
