<div
  class="p-xs pl-sm rounded-sm bg-ui-bg md:hover:bg-ui-hover flex justify-between items-center cursor-pointer"
  (click)="
    window.innerWidth < mobileMaxWidth ? openShopSelectorDialog() : menuTrigger.openMenu();
    compareWithRef.setFocusOnSearchInput()
  "
>
  <div class="flex-1 overflow-hidden pr-sm flex flex-col">
    <div class="text-sm leading-sm text-txt-secondary">Compare with</div>
    <div class="text-base leading-base truncate">{{ selectedNames.join(', ') }}</div>
  </div>

  <div
    (click)="onClickIcon($event)"
    class="flex items-center justify-center p-base bg-primary rounded-sm text-contrast"
  >
    <svg-icon
      [key]="clearable && selectedNames.length > 0 ? 'close' : 'switch'"
      class="!text-base"
    ></svg-icon>
  </div>
</div>
<div
  [matMenuTriggerFor]="window.innerWidth >= mobileMaxWidth ? compareMenu : null"
  #menuTrigger="matMenuTrigger"
  (menuClosed)="setSelections()"
></div>

<mat-menu
  #compareMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="mg-filter-menu"
>
  <div class="flex flex-col overflow-auto w-[500px]" (click)="$event.stopPropagation()">
    <mg-compare-with
      #compareWithRef
      [selections]="selections"
      [singleSelector]="singleSelector"
      [hiddenSections]="hiddenSections"
      pillSize="s"
      (selectionsChanged)="selectionsChanged.emit($event); menuTrigger.closeMenu()"
      (canceld)="menuTrigger.closeMenu()"
      [allNationals]="allNationals"
      [allGroups]="allSelectedGroups"
      [allRegions]="allRegions"
      [allShops]="allSelectedShops"
      [allStates]="allStates"
    ></mg-compare-with>
  </div>
</mat-menu>
