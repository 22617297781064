<div class="flex text-base leading-base text-txt {{ extraClass }}">
  <div class="mr-base flex text-primary !pt-xs {{ iconClass }}">
    <svg-icon [key]="icon" class="!text-sm"></svg-icon>
  </div>
  <div #contentSlot [ngClass]="{ 'flex-1': contentSlot.children.length }">
    <ng-content></ng-content>
  </div>
  <span class="flex-1" [ngClass]="{ 'break-all': shouldBreak }" *ngIf="!contentSlot.children.length">{{
    text
  }}</span>
</div>
