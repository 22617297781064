<div class="text-base font-semibold leading-base">Select shop</div>

<form class="mt-xs" [formGroup]="form">
  <mg-input
    [autoFocus]="true"
    [normalInputId]="searchInputId"
    *ngIf="allShops.length > 6"
    controlName="searchText"
    type="text"
    placeholder="Search"
    icon="search"
    [clearable]="true"
  ></mg-input>
</form>

<!-- Group Switch Tab -->
<ng-container *ngIf="showGroupSwitcher">
  <div class="flex mt-xs">
    <div
      class="tab-item"
      [ngClass]="{ '!bg-gray-100': groupTabSelected, '!border-b-0': !groupTabSelected }"
      (click)="groupTabSelected = false"
    >
      <mg-dynamic-icons extraClass="text-txt" [marketType]="marketTypes.shop"></mg-dynamic-icons>
      <div class="tab-title">My Shops</div>
    </div>
    <div
      class="tab-item"
      [ngClass]="{ '!bg-gray-100': !groupTabSelected, '!border-b-0': groupTabSelected }"
      (click)="groupTabSelected = true"
    >
      <mg-dynamic-icons extraClass="text-txt" [marketType]="marketTypes.group"></mg-dynamic-icons>
      <div class="tab-title">My Groups</div>
    </div>
  </div>
</ng-container>

<div
  class="flex flex-col gap-lg"
  [ngClass]="{ 'section-container': showGroupSwitcher, 'mt-base': !showGroupSwitcher }"
>
  <div *ngIf="showGroupSwitcher && groupTabSelected">
    @if (allGroups && allGroups.length > 0) {
      <div class="flex items-start">
        <div class="flex-1 section-pills-container">
          <mg-pill
            *ngFor="let item of selectedGroups"
            [groupId]="item.value"
            [label]="item.label"
            [size]="pillSize"
            [selected]="selectedId === item.value"
            (click)="
              selectedIdChanged.emit({
                id: item.value,
                type: marketTypes.group
              })
            "
          >
          </mg-pill>
        </div>
        <div
          *ngIf="isOrganizationOwner && hasGroupPermission"
          class="pl-xl pt-xs flex items-center text-primary cursor-pointer"
          [routerLink]="shopGroupsPagePath"
          (click)="canceld.emit()"
        >
          <span class="hidden md:block pr-sm text-base leading-base">Edit</span>
          <svg-icon key="edit" class="!text-sm"></svg-icon>
        </div>
      </div>
    } @else {
      <div class="text-sm leading-sm text-txt-secondary">
        Looks like you haven't set up any groups yet. Go to the
        <span class="text-primary underline cursor-pointer" [routerLink]="shopGroupsPagePath"
          >My Groups</span
        >
        page to get started.
      </div>
    }
  </div>

  <ng-container *ngIf="!showGroupSwitcher || !groupTabSelected">
    <div *ngFor="let group of selectedShops | keyvalue">
      <div
        *ngIf="group.key && group.key !== ''"
        class="mb-sm pl-sm text-sm leading-sm text-txt-secondary"
      >
        {{ group.key }}
      </div>
      <div class="section-pills-container">
        <mg-pill
          *ngFor="let item of group.value"
          [extraClass]="item.value === organizationId ? '!mt-0' : ''"
          [label]="item.label"
          [size]="pillSize"
          [selected]="selectedId === item.value"
          (click)="
            selectedIdChanged.emit({
              id: item.value,
              type: marketTypes.shop
            })
          "
        >
        </mg-pill>
      </div>
    </div>
  </ng-container>
</div>
