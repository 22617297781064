import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
  CalendarMaxDate,
  EndOFLastWeekString,
  PeriodType,
  PeriodTypeButtonsInfo,
  PeriodTypeToLabel,
  TodayString,
  YesterdayString
} from '@mg-platform/core/core-data-access'
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { IRangeDateInfo } from '../../interfaces/range-date.interface'
import { ISelectItem } from '@mg-platform/core/core-ui'
import moment, { Moment, isMoment } from 'moment'
import { DateRange, MatCalendar } from '@angular/material/datepicker'

@UntilDestroy()
@Component({
  selector: 'mg-range-date-picker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss'],
  standalone: false
})
export class RangeDatePickerComponent implements OnInit, OnChanges {
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>

  @Input() standalone = false
  @Input() value?: IRangeDateInfo
  @Input() maxDate?: CalendarMaxDate
  @Input() minDate?: string
  @Input() hiddenPeriods: PeriodType[] = []
  @Input() hideAllPeriods: boolean
  @Input() focusInput: 'start' | 'end' = 'start'

  @Output() valueChanged = new EventEmitter<IRangeDateInfo>()
  @Output() initailValueChanged = new EventEmitter<IRangeDateInfo>()
  @Output() canceld = new EventEmitter()

  calendarMaxDate?: string
  rangeForm: UntypedFormGroup
  periodType = PeriodType
  periodTypeButtonsInfo: ISelectItem[] = []
  periodTypeToLabel = PeriodTypeToLabel

  dateFormat = 'YYYY-MM-DD'
  validDataFormats: string[] = [this.dateFormat, 'YYYY-MM-D', 'YYYY-M-DD', 'YYYY-M-D']
  selectedDateRange: DateRange<Date>
  fromControl: AbstractControl | null
  fromTextControl: AbstractControl | null
  toControl: AbstractControl | null
  toTextControl: AbstractControl | null

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    switch (this.maxDate) {
      case 'Today':
        this.calendarMaxDate = TodayString()
        break

      case 'Yesterday':
        this.calendarMaxDate = YesterdayString()
        break

      case 'EndOFLastWeek':
        this.calendarMaxDate = EndOFLastWeekString()
        break

      default:
        this.calendarMaxDate = undefined
        break
    }
    this.periodTypeButtonsInfo = PeriodTypeButtonsInfo().filter(
      (el) => !this.hiddenPeriods.includes(el.value) && el.value !== PeriodType.Custom
    )

    if (this.value && this.value.periodType !== PeriodType.Custom) {
      this.value.rangeDate = PeriodTypeButtonsInfo().find(
        (el) => el.value === this.value?.periodType
      )?.extraInfo
    }

    const { fromInRange, toInRange } = this.getInRangeDates()

    this.rangeForm = this.fb.group({
      from: [fromInRange],
      fromText: [this.transformDate(fromInRange)],
      to: [toInRange],
      toText: [this.transformDate(toInRange)]
    })

    this.fromControl = this.rangeForm.get('from')
    this.fromTextControl = this.rangeForm.get('fromText')
    this.toControl = this.rangeForm.get('to')
    this.toTextControl = this.rangeForm.get('toText')

    this.fromControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.setFromText(value)
    })

    this.toControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.setToText(value)
    })

    this.fromTextControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      let momentDate = moment(value, this.validDataFormats, true)
      if (!value || !momentDate.isValid()) {
        return
      }
      momentDate = momentDate.utcOffset(0, true)
      if (!momentDate.isAfter(this.calendarMaxDate)) {
        this.fromControl?.setValue(momentDate, { emitEvent: false })
        if (this.value) {
          this.value.periodType = PeriodType.Custom
        } else {
          this.value = {
            periodType: PeriodType.Custom
          }
        }
        this.setCalendarDate()
        if (this.standalone) {
          this.fireValueChangedEvent()
        }
      }
    })

    this.toTextControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      let momentDate = moment(value, this.validDataFormats, true)
      if (!value || !momentDate.isValid()) {
        return
      }
      momentDate = momentDate.utcOffset(0, true)
      if (
        !momentDate.isAfter(this.calendarMaxDate) &&
        (!this.fromControl?.value ||
          momentDate.isSame(this.fromControl?.value, 'days') ||
          !momentDate.isBefore(this.fromControl?.value))
      ) {
        this.toControl?.setValue(momentDate, { emitEvent: false })
        if (this.value) {
          this.value.periodType = PeriodType.Custom
        } else {
          this.value = {
            periodType: PeriodType.Custom
          }
        }

        if (this.standalone) {
          this.fireValueChangedEvent()
        }

        this.setCalendarDate(this.toControl?.value)
      }
    })

    if (this.standalone) {
      setTimeout(() => {
        this.fireValueChangedEvent(true)
      })
    }
  }

  ngOnChanges(): void {
    if (!this.value?.rangeDate) {
      if (this.value) {
        this.value.rangeDate = {
          from: this.calendarMaxDate,
          to: this.calendarMaxDate
        }
      } else {
        this.value = {
          rangeDate: {
            from: this.calendarMaxDate,
            to: this.calendarMaxDate
          }
        }
      }
    }

    const { fromInRange, toInRange } = this.getInRangeDates()

    this.rangeForm?.patchValue(
      {
        from: fromInRange,
        fromText: this.transformDate(fromInRange),
        to: toInRange,
        toText: this.transformDate(toInRange)
      },
      { emitEvent: false }
    )
    this.setCalendarDate()
  }

  fireValueChangedEvent(initialChange = false) {
    const from = this.fromControl?.value
    let to = this.toControl?.value

    if (!to) {
      to = from
      this.toControl?.setValue(to)
    }

    const eventPayload = {
      rangeDate: {
        from: isMoment(from) ? from.utc().format() : from,
        to: isMoment(to) ? to.utc().format() : to
      },
      periodType: this.value?.periodType
    }

    if (initialChange) {
      this.initailValueChanged.emit(eventPayload)
    } else {
      this.valueChanged.emit(eventPayload)
    }
  }

  setDateByPeriodType(item: ISelectItem) {
    this.focusInput = 'start'
    if (this.value) {
      this.value.periodType = item.value
    } else {
      this.value = {
        periodType: item.value
      }
    }

    this.rangeForm.patchValue({
      from: item.extraInfo.from.isAfter(this.calendarMaxDate)
        ? moment(this.calendarMaxDate).utc()
        : item.extraInfo.from,
      to: item.extraInfo.to.isAfter(this.calendarMaxDate)
        ? moment(this.calendarMaxDate).utc()
        : item.extraInfo.to
    })
    this.setCalendarDate()

    if (this.standalone) {
      this.fireValueChangedEvent()
    }
  }

  transformDate(value?: Moment) {
    return `${value?.format(this.dateFormat)}`
  }

  onSelectedDateChange(date: Date): void {
    if (this.focusInput === 'start') {
      if (this.selectedDateRange?.end && date < this.selectedDateRange.end) {
        this.selectedDateRange = new DateRange(date, this.selectedDateRange.end)
      } else {
        this.selectedDateRange = new DateRange(date, null)
      }
      this.focusInput = 'end'
    } else if (this.selectedDateRange.start && date >= this.selectedDateRange.start) {
      this.selectedDateRange = new DateRange(this.selectedDateRange.start, date)
      this.focusInput = 'start'
    }

    this.fromControl?.setValue(this.selectedDateRange.start)
    this.toControl?.setValue(
      this.selectedDateRange.end ? this.selectedDateRange.end : this.selectedDateRange.start
    )

    if (this.value) {
      this.value.periodType = PeriodType.Custom
    } else {
      this.value = {
        periodType: PeriodType.Custom
      }
    }
    if (this.standalone) {
      this.fireValueChangedEvent()
    }
  }

  public setCalendarDate(activeDate?: Date) {
    setTimeout(() => {
      this.selectedDateRange = new DateRange(this.fromControl?.value, this.toControl?.value)
      this.setCalendarActiveDate(activeDate ?? this.fromControl?.value)
    })
  }

  getInRangeDates() {
    const from = moment(this.value?.rangeDate?.from).utc()
    const to = moment(this.value?.rangeDate?.to).utc()
    const fromInRange = from.isAfter(this.calendarMaxDate) ? moment(this.calendarMaxDate).utc() : from
    const toInRange = to.isAfter(this.calendarMaxDate) ? moment(this.calendarMaxDate).utc() : to

    return { fromInRange, toInRange }
  }

  setCalendarActiveDate(activeDate?: Date) {
    if (this.calendar && activeDate) {
      this.calendar.activeDate = activeDate
    }
  }

  setFromText(value: Moment) {
    if (value) {
      this.fromTextControl?.setValue(this.transformDate(value), {
        emitEvent: false
      })
    }
  }

  setToText(value: Moment) {
    if (value) {
      this.toTextControl?.setValue(this.transformDate(value), {
        emitEvent: false
      })
    }
  }
}
