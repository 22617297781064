export enum ShopFocusType {
  NotSet = 'NotSet',
  Mechanical = 'Mechanical',
  Tire = 'Tire',
  Mechanical_Tire = 'Mechanical_Tire'
}

export const ShopFocusTypeToShortLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  Mechanical: 'Mechanical',
  Tire: 'Tires',
  Mechanical_Tire: 'Mechanical/Tires'
}

export const ShopFocusTypeToLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  Mechanical: 'Tires account for more than 70% of sales',
  Tire: 'Tires account for around 50% of sales',
  Mechanical_Tire: 'Tires account for less than 30% of sales'
}
