import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { FullScreenModalConfig, MobileMaxWidth } from '@mg-platform/core/core-util'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ItemSelectorDialogComponent } from '../item-selector-dialog/item-selector-dialog.component'

@UntilDestroy()
@Component({
  selector: 'mg-item-selector-filter',
  templateUrl: './item-selector-filter.component.html',
  styleUrls: ['./item-selector-filter.component.scss'],
  standalone: false
})
export class ItemSelectorFilterComponent implements OnChanges {
  @Input() isGroup = false
  @Input() items: ISelectItem[] = []
  @Input() selectedItem: any
  @Input() selectedLabel: string
  @Input() icon = 'garage'

  @Output() selectedItemChanged = new EventEmitter<any>()

  window = window
  mobileMaxWidth = MobileMaxWidth

  constructor(private dialogService: MatDialog) {}

  ngOnChanges(): void {
    this.selectedLabel = this.items.find((el) => el.value === this.selectedItem)?.label ?? ''
  }

  openItemSelectorDialog() {
    this.dialogService
      .open(ItemSelectorDialogComponent, {
        ...FullScreenModalConfig,
        data: {
          items: this.items,
          selectedItem: this.selectedItem,
          isGroup: this.isGroup
        }
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.selectedItemChanged.emit(value)
        }
      })
  }
}
