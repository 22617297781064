<div
  class="w-full bg-ui-bg px-sm py-base lg:hidden flex items-center justify-between"
  style="z-index: 1000"
>
  <div class="p-sm flex pt-sm cursor-pointer text-txt" (click)="mainSidebar.open()">
    <svg-icon key="menu" class="!text-lg"></svg-icon>
  </div>

  <div class="grow-0 shrink-0 cursor-pointer" routerLink="/dashboard">
    <img src="assets/images/icons/logo-full.svg" class="h-[40px]" alt="Logo" />
  </div>

  <div class="p-sm flex cursor-pointer text-txt" (click)="profileSidebar.open()">
    <svg-icon key="user" class="!text-lg"></svg-icon>
  </div>
</div>

<div sidebarjs="mainSidebar">
  <mg-menu-header-items
    [routes]="mainMenuRoutes"
    [profileMenu]="false"
    (itemClicked)="mainSidebar.close()"
  ></mg-menu-header-items>
</div>

<div sidebarjs="profileSidebar">
  <mg-menu-header-items
    [routes]="profileMenuRoutes"
    [profileMenu]="true"
    [showThemeOptions]="showThemeOptions"
    (itemClicked)="profileSidebar.close()"
    (themeOptionsStatusChanged)="showThemeOptions = $event"
  ></mg-menu-header-items>
</div>
