import { IShop } from './shop.interface'

export interface IShopGroup {
  id: string
  name: string
  isDefaultOrganizationGroup: boolean
  displayUnderlyingShops: boolean
  isPublic: boolean
  shops: IShop[]
  groupUsers: string[]
  numberOfShops: number
}

export interface IUpdateShopGroup {
  groupId?: string
  name: string
  isPublic: boolean
  displayUnderlyingShops: boolean
  shopIds: string[]
  userIds: string[]
}
