<div class="bg-contrast h-full w-full relative p-lg overflow-auto">
  <!-- Close Btn -->
  <div class="absolute text-txt-secondary cursor-pointer top-lg right-2xl">
    <svg-icon key="close" class="!text-base" (click)="dialogRef.close()"></svg-icon>
  </div>

  <mg-shop-selector
    pillSize="m"
    [showOrganizationAverage]="data.showOrganizationAverage"
    [showGroup]="data.showGroup"
    [selectedId]="data.selectedId"
    (selectedIdChanged)="dialogRef.close($event)"
    (canceld)="dialogRef.close()"
  ></mg-shop-selector>
</div>
