import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { commonIcons } from '@mg-platform/icons'
import { TestComponent } from './components/test/test.component'
import { InputComponent } from './components/input/input.component'
import { DirectivesModule } from '@mg-platform/core/core-util'
import { ButtonComponent } from './components/button/button.component'
import { NgxMaskModule } from 'ngx-mask'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { GooglePlaceInputComponent } from './components/google-place-input/google-place-input.component'
import { CircleChartComponent } from './charts/circle-chart/circle-chart.component'
import { SimpleBarChartComponent } from './charts/simple-bar-chart/simple-bar-chart.component'
import { ButtonGroupComponent } from './components/button-group/button-group.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { CheckboxComponent } from './components/checkbox/checkbox.component'
import { NgxPaginationModule } from 'ngx-pagination'
import { PaginationComponent } from './components/pagination/pagination.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { SearchInputComponent } from './components/search-input/search-input.component'
import { RouterModule } from '@angular/router'
import { GoogleMapsModule } from '@angular/google-maps'
import { BarChartComponent } from './charts/bar-chart/bar-chart.component'
import { LoadingComponent } from './components/loading/loading.component'
import { TippyTooltipComponent } from './components/tippy-tooltip/tippy-tooltip.component'
import { NgxTippyModule } from 'ngx-tippy-wrapper'
import { NGX_TIPPY_CONFIG } from 'ngx-tippy-wrapper'
import { followCursor } from 'tippy.js'
import { RadioComponent } from './components/radio/radio.component'
import { TimePickerComponent } from './components/time-picker/time-picker.component'
import { TextFieldModule } from '@angular/cdk/text-field'
import { CheckboxElementComponent } from './components/checkbox-element/checkbox-element.component'
import { NgxCurrencyDirective } from 'ngx-currency'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SvgIconsModule.forRoot({ icons: commonIcons }),
    DirectivesModule,
    NgxMaskModule.forRoot(),
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxCurrencyDirective,
    NgxPaginationModule,
    RouterModule,
    GoogleMapsModule,
    NgxTippyModule,
    TextFieldModule
  ],
  declarations: [
    TestComponent,
    InputComponent,
    ButtonComponent,
    GooglePlaceInputComponent,
    CircleChartComponent,
    SimpleBarChartComponent,
    ButtonGroupComponent,
    CheckboxComponent,
    PaginationComponent,
    TabsComponent,
    SearchInputComponent,
    BarChartComponent,
    LoadingComponent,
    TippyTooltipComponent,
    RadioComponent,
    TimePickerComponent,
    CheckboxElementComponent
  ],
  exports: [
    TestComponent,
    InputComponent,
    ButtonComponent,
    GooglePlaceInputComponent,
    CircleChartComponent,
    SimpleBarChartComponent,
    ButtonGroupComponent,
    CheckboxComponent,
    PaginationComponent,
    TabsComponent,
    SearchInputComponent,
    BarChartComponent,
    LoadingComponent,
    TippyTooltipComponent,
    RadioComponent,
    TimePickerComponent,
    CheckboxElementComponent
  ],
  providers: [
    {
      provide: NGX_TIPPY_CONFIG,
      useValue: {
        plugins: [followCursor]
      }
    }
  ]
})
export class CoreUiModule {}
