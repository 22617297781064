<div
  class="w-full md:w-[244px] p-xs pl-sm rounded-sm bg-ui-bg md:hover:bg-ui-hover flex justify-between items-center cursor-pointer"
  (click)="window.innerWidth < mobileMaxWidth ? openItemSelectorDialog() : menuTrigger.openMenu()"
>
  <div class="flex-1 overflow-hidden pr-sm flex flex-col">
    <div class="text-sm leading-sm text-txt-secondary">Select group</div>
    <div class="text-base leading-base truncate">{{ selectedLabel }}</div>
  </div>

  <div class="flex items-center justify-center p-base bg-primary rounded-sm text-contrast">
    <svg-icon [key]="icon" class="!text-base"></svg-icon>
  </div>
</div>
<div [matMenuTriggerFor]="itemSelectorMenu" #menuTrigger="matMenuTrigger"></div>

<mat-menu
  #itemSelectorMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="mg-filter-menu"
>
  <div class="flex flex-col overflow-auto w-[500px] p-base" (click)="$event.stopPropagation()">
    <mg-item-selector
      pillSize="s"
      [items]="items"
      [isGroup]="isGroup"
      [selectedItem]="selectedItem"
      (selectedItemChanged)="selectedItemChanged.emit($event); menuTrigger.closeMenu()"
    ></mg-item-selector>
  </div>
</mat-menu>
