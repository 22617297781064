<div class="bg-contrast h-full w-full relative flex flex-col overflow-auto">
  <!-- Close Btn -->
  <div *ngIf="data.singleSelector" class="absolute text-txt-secondary cursor-pointer top-lg right-2xl">
    <svg-icon key="close" class="!text-base" (click)="dialogRef.close()"></svg-icon>
  </div>

  <mg-compare-with
    [selections]="data.selections"
    [singleSelector]="data.singleSelector"
    [hiddenSections]="data.hiddenSections"
    pillSize="m"
    (selectionsChanged)="dialogRef.close($event)"
    (canceld)="dialogRef.close()"
    [allNationals]="data.allNationals"
    [allGroups]="data.allGroups"
    [allRegions]="data.allRegions"
    [allShops]="data.allShops"
    [allStates]="data.allStates"
  ></mg-compare-with>
</div>
