<div class="text-base font-semibold leading-base">Select Group</div>

<div class="mt-lg flex flex-wrap gap-sm">
  <mg-pill
    *ngFor="let item of items"
    [groupId]="isGroup ? item.value : undefined"
    [label]="item.label"
    [size]="pillSize"
    [selected]="selectedItem === item.value"
    (click)="selectedItemChanged.emit(item.value)"
  ></mg-pill>
</div>
