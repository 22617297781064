<div class="p-base h-full flex flex-col overflow-y-auto overflow-x-hidden {{ extraClasses }}">
  <div class="pr-2xl md:pr-0 text-base leading-base font-semibold text-txt">Theme Options</div>

  <form [formGroup]="form" class="flex-1 flex flex-col">
    <div class="mt-lg md:mt-xl mb-base text-txt text-sm leading-sm">Modes</div>

    <div class="flex flex-col gap-base md:gap-sm">
      <div
        *ngFor="let item of darkModeTypes"
        (click)="form.get('type')?.setValue(item.value)"
        class="p-sm pl-base text-txt flex items-center bg-contrast border border-ui-separator rounded-sm cursor-pointer"
        [ngClass]="{
          'bg-primary border-primary md:hover:bg-primary text-white':
            form.get('type')?.value === item.value,
          'md:hover:bg-ui-hover': form.get('type')?.value !== item.value
        }"
      >
        <div class="flex mr-sm">
          <svg-icon [key]="item.value + '-mode'" class="!text-base"></svg-icon>
        </div>
        <div class="text-base leading-base">{{ item.label }}</div>
      </div>
    </div>

    <div class="mt-lg md:mt-xl mb-base text-sm text-txt leading-sm">Color theme</div>

    <div
      *ngFor="let item of colorPalettes"
      class="mb-base md:mb-sm last:mb-0 p-base rounded-sm flex items-center bg-contrast border border-ui-separator cursor-pointer"
      [ngClass]="{
        'bg-primary md:hover:bg-primary border-primary': item.value === this.form.get('palette')?.value,
        'md:hover:bg-ui-hover': item.value !== this.form.get('palette')?.value
      }"
      (click)="this.form.get('palette')?.setValue(item.value)"
    >
      <div class="flex items-center">
        <div
          *ngFor="let color of item.extraInfo"
          class="w-lg h-lg rounded-full mr-base last:mr-0"
          [style.background]="color"
        ></div>
      </div>
      <div
        class="flex-1 text-right text-sm leading-sm text-txt-secondary"
        [ngClass]="{ 'text-white': item.value === this.form.get('palette')?.value }"
      >
        {{ item.label }}
      </div>
    </div>
  </form>
</div>
