import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { UntilDestroy } from '@ngneat/until-destroy'
import { PillSize } from '../pill/pill.component'

@UntilDestroy()
@Component({
  selector: 'mg-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss'],
  standalone: false
})
export class ItemSelectorComponent {
  @Input() isGroup = false
  @Input() items: ISelectItem[] = []
  @Input() selectedItem: any
  @Input() pillSize: PillSize = 'm'

  @Output() selectedItemChanged = new EventEmitter<any>()
}
