import { Component, EventEmitter, Inject, Input, Output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UntilDestroy } from '@ngneat/until-destroy'
import { IHeaderRouteInfo } from '../main-header/main-header.interface'
import { Store } from '@ngxs/store'
import { Logout } from '@mg-platform/users/users-data-access'
import { Router } from '@angular/router'
import { StateResetAll } from 'ngxs-reset-plugin'

@UntilDestroy()
@Component({
  selector: 'mg-menu-header-items',
  templateUrl: './menu-header-items.component.html',
  styleUrls: ['./menu-header-items.component.scss'],
  standalone: false
})
export class MenuHeaderItemsComponent {
  @Input() routes: IHeaderRouteInfo[]
  @Input() profileMenu: boolean
  @Input() showThemeOptions = false

  @Output() itemClicked = new EventEmitter()
  @Output() themeOptionsStatusChanged = new EventEmitter<boolean>()

  constructor(
    private store: Store,
    private router: Router
  ) {}

  logout() {
    this.store.dispatch(new Logout()).subscribe(() => {
      this.router.navigateByUrl('/login')
      this.store.dispatch(new StateResetAll())
    })
  }
}
