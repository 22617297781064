import { ITimeBaseRequest } from './base-request.interface'

export type ICompareWithType = 'shops' | 'states' | 'regions' | 'nationals' | 'groups'

export interface ICompareWithSelection {
  shops: string[]
  states: string[]
  regions: string[]
  nationals: string[]
  groups: string[]
}

export interface ICompareWithSelectionAndTime extends ICompareWithSelection, ITimeBaseRequest {}

export const CompareWithInitialValue: ICompareWithSelection = {
  shops: [],
  states: [],
  regions: [],
  nationals: [],
  groups: []
}
