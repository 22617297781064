import { Injectable } from '@angular/core'
import { FiltersState, ICompareWithSelection, StatesState } from '@mg-platform/core/core-data-access'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { Store } from '@ngxs/store'
import { ShopGroupsState, ShopsState } from '@mg-platform/shops/shops-data-access'
import { UserAccountState } from '@mg-platform/users/users-data-access'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { environment } from '@mg-platform/core/core-util'

type GAEvent =
  | 'page_view'
  | 'mg_print'
  | 'mg_goal_calculate_forecast'
  | 'mg_benchmark_custom_date'
  | 'mg_trends_add_view'
  | 'mg_trends_edit_view'
  | 'mg_trends_delete_view'
  | 'mg_market_pricing_update'
  | 'mg_tire_trends_add'
  | 'mg_tire_trends_edit'
  | 'mg_tire_trends_delete'

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  constructor(
    private store: Store,
    private gtmService: GoogleTagManagerService
  ) {}

  sendEvent(info: {
    event: GAEvent
    extraProps?: { [key: string]: string }
    includeShop?: boolean
    includeGroupId?: string
    compareItems?: { [key: string]: ICompareWithSelection }
  }) {
    const currentUser = this.store.selectSnapshot(UserAccountState.currentUser)
    const payload: { [key: string]: string } = {
      page_title: window.document.title,
      page_location: window.location.href,
      email: currentUser?.email ?? 'Not Login',
      ...(info.extraProps ?? {})
    }
    if (info.includeShop) {
      const shop = this.store.selectSnapshot(ShopsState.shop)
      payload['primaryShop'] = shop.displayName
    }
    if (info.includeGroupId) {
      const group = this.store
        .selectSnapshot(ShopGroupsState.groups)
        ?.find((el) => el.id === info.includeGroupId)
      payload['primaryShop'] = group?.name ?? ''
    }
    for (const key in info.compareItems ?? {}) {
      const element = info.compareItems?.[key]
      if (element) {
        const allCompareNames = this.generateCompareWithSelectedNames(element)
        const finalCompareNames = [
          ...allCompareNames.slice(0, 5),
          allCompareNames.length > 5 ? allCompareNames.length : undefined
        ]
          .filter((el) => el !== undefined)
          .join(', ')
          .trim()
        if (finalCompareNames !== '') {
          payload[key] = finalCompareNames
        }
      }
    }

    if (environment.name !== 'local') {
      this.gtmService.pushTag({
        event: `gtm_${info.event}`,
        ...payload
      })
    }
  }

  generateCompareWithSelectedNames(data: ICompareWithSelection): string[] {
    const allShops = (this.store.selectSnapshot(ShopsState.allShops) ?? []).map((el) => ({
      label: el.displayName,
      value: el.id
    }))
    const allStates = (this.store.selectSnapshot(StatesState.states) ?? []).map((el) => ({
      label: el.stateName,
      value: el.stateId
    }))
    const allRegions = (this.store.selectSnapshot(StatesState.regions) ?? []).map((el) => ({
      label: el.regionName,
      value: el.regionId
    }))
    const allGroups = (this.store.selectSnapshot(ShopGroupsState.groups) ?? []).map((el) => ({
      label: el.name,
      value: el.id
    }))
    const allNationals = this.store.selectSnapshot(FiltersState.allCountries)

    const { nationals, regions, shops, states, groups } = data

    return [
      ...this.getSlectedNamesForSection(shops, allShops),
      ...this.getSlectedNamesForSection(states, allStates),
      ...this.getSlectedNamesForSection(regions, allRegions),
      ...this.getSlectedNamesForSection(groups, allGroups),
      ...this.getSlectedNamesForSection(nationals, allNationals)
    ].flat()
  }

  private getSlectedNamesForSection(values: string[] = [], section: ISelectItem[] = []) {
    return values.map((el) => section.find((x) => x.value === el)?.label ?? '')
  }
}
